import React, { useState } from "react"
import styled from "styled-components"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

import Layout from "../components/Layout"
import Menu from "../components/menus/Menu"

const Page = styled.section`
  padding: 1rem;
  padding-top: 2.5rem;
  background-color: ${props =>
    props.theme.light ? props.theme.light : "black"};
  font-family: ${props =>
    props.theme.fonts ? props.theme.fonts.bodyFont : "Arial, sans-serif"};
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: 100vh;
  @media (min-width: 1024px) {
    padding-top: 2rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
`

const MenuWrap = styled.div`
  padding: 1rem 0;
`

const GonationLogo = styled.div`
  border-top: 3px solid $secondary;
  text-align: center;
  padding: 1rem 0;
  img {
    max-width: 200px;
  }
`

const GonationQr = () => {
  const [selectedMenu, setSelectedMenu] = useState("Food")
  const options = ["Food", "Drink", "Beer"]

  const defaultOption = "Food"

  const renderMenuSelection = () => {
    switch (selectedMenu) {
      case "Food":
        return (
          <Menu
            poweredListID={"powered-list-3"}
            mode={"cellsThenAllInOnce"}
            noBack
          />
        )
      case "Drink":
        return (
          <>
            <span></span>
            <Menu
              poweredListID={"powered-list-1"}
              mode={"cellsThenAllInOnce"}
              noBack
            />
          </>
        )

      case "Beer":
        return (
          <iframe
            src="https://business.untappd.com/embeds/iframes/30566/117794"
            frameborder="0"
            height="4300"
            width="100%"
          ></iframe>
        )
      default:
        return ""
    }
  }

  return (
    <Layout pageTitle="Digital QR Menu" noHero noFooter cta="events" noNav>
      <Page>
        <Title>Select a menu</Title>
        <Dropdown
          options={options}
          onChange={({ value }) => setSelectedMenu(value)}
          value={selectedMenu}
          placeholder="Select a menu"
        />
        <MenuWrap>{renderMenuSelection(selectedMenu)}</MenuWrap>
      </Page>
      <GonationLogo>
        <img
          src="https://www.gonationsites.com/GNSE/gn-sites/images/gn-power-black.svg"
          alt={"GoNation"}
        />
      </GonationLogo>
    </Layout>
  )
}

export default GonationQr
